<template>
    <div>
      <modal :show.sync="showModal">
        <slot name="close-button">
          <button
            type="button"
            class="model-close-btn"
            @click="showModal = false"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span :aria-hidden="true">×</span>
          </button>
        </slot>
  
        <div>
          <p class="font-bold"> Updated At </p>
          
            <div v-if="!isValidDate(selectedItem)">
                {{ selectedItem }}
            </div>
            <div v-else>
                <date-format :date="selectedItem" />
            </div>    
        </div>
      </modal>
    </div>
  </template>
  
  <script>
  import Modal from "@/components/Modal.vue";
  import DateFormat from "@/views/common/DateFormat.vue";
  export default {
    components: {
      Modal,
      DateFormat
    },
    data: function() {
      return {
        showModal: false,
      };
    },
    props: {
      selectedItem: {
        type: String
      }
    },
    methods: {
      /**
       * Opens the modal and sets the missing reasons.
       */
      openModal() {
        this.showModal = true;
      },

      /**
       * Checks if the input date can be parsed into a valid JavaScript Date object.
       */
      isValidDate(date) {
        return !isNaN(Date.parse(date)); // Returns true if date is valid
      }
    }
  };
  </script>
  <style></style>
  