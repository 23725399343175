<template>
  <div>
    <loader :isLoader="showLoader"></loader>
    <v-data-table
      v-model="selected"
      :headers="getCustomerTableHeaders()"
      :items="tableData"
      :single-select="singleSelect"
      :hide-default-footer="isPagination ? false : true"
      select-enable
      class="elevation-1"
      :items-per-page="isPagination ? customersPerPageItem : tableData.length"
      :mobile-breakpoint="0"
    >

      <template v-slot:item.createdAt="{item}">
        <date-format
          :date="item.createdAt"
        />
      </template>

      <template v-slot:item.updatedAt="{item}">
        <date-format
          :date="item.updatedAt"
        />
      </template>

      <template v-slot:item.action="{ item }">
        <div v-if="isTariff" @click="removeItem(item)" class="text-center">
          <!-- <i class="fa fa-times" aria-hidden="true"></i> -->
        </div>
        <div v-else class="d-flex">
          <base-button
            type="primary"
            class="btn-theme-EFEBEB left-border btn-br1"
            @click="editCustomer(item.id)"
          >
            <img src="../../assets/img/edit.png"/>
          </base-button>
         
          <base-button
            type="primary"
            class="btn-theme-EFEBEB table-action-button right-border"
            @click="showDeleteConfirmationModal(item.id)"
          >
            <img src="../../assets/img/trash.png"/>
          </base-button>
          
        </div>
      </template>
      
    </v-data-table>
    
    <!-- Modal component for displaying the selected updated date. -->
    <view-updated-date-modal
      ref="viewUpdatedAtModal"
      :selectedItem="selectedUpdatedAt"
    />

    <confirmation-modal 
      ref="confirmationModal"
      @delete="deleteCustomer" 
    />

  </div>
</template>

<script>
import Loader from "@/views/common/Loader.vue";
import ConfirmationModal from "@/views/common/ConfirmationModal.vue";
import DateFormat from "@/views/common/DateFormat.vue";
import { API, masterAPI } from "@/api/API";
import { tablePaginationPerPageItem } from "@/helpers/utility";
import ViewUpdatedDateModal from "@/views/common/ViewUpdatedDateModal.vue";

export default {
  bodyClass: "landing",
  components: {
    Loader,
    ConfirmationModal,
    DateFormat,
    ViewUpdatedDateModal
  },
  data: function() {
    return {
      // Component's data properties
      singleSelect: false,
      selected: [],
      headers: [
        { text: "Customer Name", value: "name", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Updated On", value: "updatedAt", sortable: false },
        { text: "Action", value: "action", class: "custom-th-class", sortable: false }
      ],
      showLoader: false,
      selectedCustomerId: null,
      customersPerPageItem: tablePaginationPerPageItem.customers,
      selectedUpdatedAt: ""
    };
  },
  props: {
    // Component props
    tableData: {
      type: Array
    },
    groupDetail: {
      type: Object
    },
    isTariff: {
      type: Boolean
    },
    isPagination: {
      type: Boolean
    },
    isAction: {
      type: Boolean
    },
    sectionName: {
      type: String
    }
  },
  methods: {
    /**
     * Remove an item from the table.
     * @param {Object} item - The item to be removed.
     */
    removeItem(item) {
      // Emit an event to notify parent component to remove the item
      this.$emit("remove-item", item);
    },
    editCustomer(customerId) {
      this.$router.push({
        name: "editCustomer",
        params: {
          view: 'edit-customer',
          id: customerId,
        }
      });
    },
    /**
     * Shows the delete confirmation modal for a selected record.
     * @param {string} selectedRecordId - The ID of the record to be deleted.
    */
    showDeleteConfirmationModal(selectedRecordId) {
      this.selectedCustomerId = selectedRecordId;
      this.$refs.confirmationModal.showDeleteConfirmation();
    },
    /**
      * Deletes a customer by making an API call
    */
    async deleteCustomer() {
      if (this.selectedCustomerId) {
        this.showLoader = true;

        if (this.groupDetail) {
          let customerIdList = this.groupDetail.customers.filter(item => item !== this.selectedCustomerId);
          let groupName = this.groupDetail.name;

          let bodyPayload = {
            name: groupName,
            customers: customerIdList,
          };

          let response = await masterAPI(
            API.API_ENDPOINT.customerGroup,
            API.API_METHOD.put,
            this.groupDetail.id,
            JSON.stringify(bodyPayload)
          );

          this.showLoader = false;
          if (response.status == 200) {
            this.$toast.success("Customer removed successfully from "+groupName+" group.");
            this.$emit("remove-item", this.sectionName);
          } 
        } else {

          let response = await masterAPI(
            API.API_ENDPOINT.customer,
            API.API_METHOD.delete,
            this.selectedCustomerId
          );

          this.showLoader = false;

          if (response.status == 200) {
            this.$toast.success("Customer deleted successfully");
            this.$emit("remove-item", this.sectionName);
          } 
        }
      }
    },
    /**
     * Get the headers for the table, optionally including the action column.
    */
    getCustomerTableHeaders() {
      // If isAction is true, return the full headers array including the action column.
      if (this.isAction) {
        return this.headers; 
      } else {
        // If isAction is false, filter out the action column from the headers array.
        return this.headers.filter((header) => header.value !== "action");
      }
    },
    /**
     * Opens a modal to view updated date of a selected item.
     *
     * @param {Object} item - The selected item to view updated date for.
     */
    viewUpdatedAt(item) {
      this.selectedUpdatedAt = item.updatedAt;
      this.$refs.viewUpdatedAtModal.openModal();
    }
  }
};
</script>

<style></style>
