<template>
  <modal :show.sync="showModal">
      <div class="text-center">
        <h6>
          {{ isDeleteMultiple ? 
            'Are you sure you want to delete selected items. This action is not reversible. All tariff deleted will no longer be available in Tariff until they are added again' : 
            'Are you sure you want to delete the selected item?' 
          }}
        </h6>

        <div>
          <button 
            type="button" 
            class="btn mt-3 btn-light"
            @click="hideDeleteConfirmation"
          >
            No
          </button>
          <button 
            type="button" 
            class="btn mt-3 btn-default"
            @click="deleteItem"
          >
            Yes
          </button>
        </div>
      </div>
    </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal
  },
  data() {
    return {
      showModal: false
    };
  },
  props: {
    // Component props
    isDeleteMultiple: {
      type: Boolean,
      default: false, // Default value is false
    }
  },
  methods: {
    /**
     * Show the delete confirmation modal.
     */
    showDeleteConfirmation() {
      this.showModal = true;
    },
    /**
     * Hide the delete confirmation modal.
     */
    hideDeleteConfirmation() {
      this.showModal = false;
    },
    /**
     * Handle the deletion of the item.
     * Emits the 'delete' event and hides the confirmation modal.
     */
    deleteItem() {
      // Emit the 'delete' event to notify the parent component
      this.$emit('delete');
      
      // Hide the delete confirmation modal
      this.hideDeleteConfirmation();
    }
  }
};
</script>