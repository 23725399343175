<template>
  <div>
    <loader :isLoader="showLoader"></loader>
    <v-app id="inspire">
      <v-data-table
        :headers="getTableHeaders()"
        :items="tableData"
        :expanded.sync="expandedItems"
        item-key="id"
        :hide-default-footer="isPagination ? false : true"
        class="elevation-1"
        :items-per-page="isPagination ? customerGroupsPerPageItem : tableData.length"
        :mobile-breakpoint="0"
      >
        <template v-slot:item.expandedIcon="{ item }">
          <v-icon
            v-if="item.children && item.children.length > 0"
            @click="toggleRow(item)"
            :class="['expand-icon', expandedItems.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down']"
          >
            {{ expandedItems.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </template>

        <template v-slot:item.createdAt="{item}">
          <date-format
            :date="item.createdAt"
          />
        </template>

        <template v-slot:item.updatedAt="{item}">
          <date-format
            :date="item.updatedAt"
          />
        </template>

        <template v-slot:item.action="{ item }">
          <div v-if="isTariff" @click="removeItem(item)" class="text-center">
            <i v-if="isParentView" class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div v-else class="d-flex">
            <base-button
              type="primary"
              class="btn-theme-EFEBEB left-border btn-br1"
              @click="editCustomerGroup(item.id)"
            >
              <img src="../../assets/img/edit.png"/>
            </base-button>
          
            <base-button
              type="primary"
              class="btn-theme-EFEBEB table-action-button right-border"
              @click="showDeleteConfirmationModal(item.id)"
            >
              <img src="../../assets/img/trash.png"/>
            </base-button>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="sub-lane-data-table">
            <v-simple-table>
              <template v-slot:default>
                <div v-if="item.children">
                  <customer-table
                    :tableData="item.children"
                    :groupDetail="item"
                    :isTariff="isTariff"
                    :isPagination="false"
                    :isAction="isAction"
                    :sectionName="sectionName"
                    @remove-item="removeItem"
                  />
                </div>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-app>

    <!-- Modal component for displaying the selected updated date. -->
    <view-updated-date-modal
      ref="viewUpdatedAtModal"
      :selectedItem="selectedUpdatedAt"
    />

    <confirmation-modal 
      ref="confirmationModal"
      @delete="deleteCustomerGroup" 
    />
  </div>
</template>

<script>

import Loader from "@/views/common/Loader.vue";
import ConfirmationModal from "@/views/common/ConfirmationModal.vue";
import CustomerTable from "@/views/common/CustomerTable.vue";
import DateFormat from "@/views/common/DateFormat.vue";
import { API, masterAPI } from "@/api/API";
import { tablePaginationPerPageItem } from "@/helpers/utility";
import ViewUpdatedDateModal from "@/views/common/ViewUpdatedDateModal.vue";

export default {
  bodyClass: "landing",
  components: {
    Loader,
    ConfirmationModal,
    CustomerTable,
    DateFormat,
    ViewUpdatedDateModal
  },
  data: function() {
    return {
      // Component's data properties
      headers: [
        { text: "", value: "expandedIcon", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Updated On", value: "updatedAt", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      expandedItems: [],
      showLoader: false,
      selectedCustomerId: null,
      customerGroupsPerPageItem: tablePaginationPerPageItem.customerGroups,
      selectedUpdatedAt: ""
    };
  },
  props: {
    // Component props
    tableData: {
      type: Array,
    },
    sectionName: {
      type: String
    },
    isTariff: {
      type: Boolean
    },
    isParentView: {
      type: Boolean
    },
    isPagination: {
      type: Boolean,
    },
    isAction: {
      type: Boolean,
    },
  },
  methods: {
    /**
     * Toggle the expansion state of a table row.
     * @param {Object} item - The item to be toggled.
     */
    toggleRow(item) {
      const index = this.expandedItems.indexOf(item);
      if (index === -1) {
        this.expandedItems.push(item);
      } else {
        this.expandedItems.splice(index, 1);
      }
    },
    /**
     * Remove an item from the table.
     * @param {Object} item - The item to be removed.
     */
    async removeItem(item) {
      this.$emit("remove-item", item);
      this.expandedItems = [];
    },
    /**
     * Shows the delete confirmation modal for a selected record.
     * @param {string} selectedRecordId - The ID of the record to be deleted.
    */
    showDeleteConfirmationModal(selectedRecordId) {
      this.selectedCustomerId = selectedRecordId;
      this.$refs.confirmationModal.showDeleteConfirmation();
    },
    /**
     * Navigates to the customer group edit view using Vue Router.
     * @param {number} customerGroupId - The ID of the customer group to edit.
    */
    editCustomerGroup(customerGroupId) {
      this.$router.push({
        name: "editCustomer",
        params: {
          view: 'edit-customer-group',
          id: customerGroupId,
        }
      });
    },
    /**
     * Deletes a customer group and handles the API response.
    */
    async deleteCustomerGroup() {
      if (this.selectedCustomerId) {
        this.showLoader = true;
        let response = await masterAPI(
          API.API_ENDPOINT.customerGroup,
          API.API_METHOD.delete,
          this.selectedCustomerId
        );

        this.showLoader = false;
        if (response.status == 200) {
          this.$toast.success("Customer group deleted successfully");
          this.$emit("remove-item", this.sectionName);
        } 
      }
    },
    /**
     * Get the headers for the table, optionally including the action column.
    */
    getTableHeaders() {
      // If isAction is true, return the full headers array including the action column.
      if (this.isAction) {
        return this.headers; 
      } else {
        // If isAction is false, filter out the action column from the headers array.
        return this.headers.filter((header) => header.value !== "action");
      }
    },
    /**
     * Opens a modal to view updated date of a selected item.
     *
     * @param {Object} item - The selected item to view updated date for.
     */
    viewUpdatedAt(item) {
      this.selectedUpdatedAt = item.updatedAt;
      this.$refs.viewUpdatedAtModal.openModal();
    }
  },
};
</script>

<style>
.expand-icon {
  cursor: pointer;
}
</style>
