<template>
    <div>
        <div v-if="selectedBaseUnit">
            <div class="create-section-text mb-2">
                Choose a Class
            </div>
           
            <div class="field-dropdown-main mb-4 price-method-dropdwon">
                <choices-single 
                    v-model="localSelectedClassUnits" 
                    :options="classUnitsListResult"
                    @input="event => handleSelectClassUnits(event)" 
                    :value="localSelectedClassUnits" 
                    id="selectedClassUnitsList"
                >
                    <option value="0">Select class</option>
                </choices-single>

                <span v-if="classRateInvalid" class="validate-error">
                    The Class is required
                </span>
            </div>
        </div>

        <div v-if="localSelectedClassUnits">
            <!-- View Class Button -->
            <div>
                <base-button
                    type="submit"
                    class="btn btn-default px-2 mt-2 mb-4"
                    v-on:click="showClassBreaks"
                >
                    <img src="../../assets/img/plus-active.svg" class=" pr-2" />
                    <span class="pr-3">
                        View Class Breaks and Apply Discounts 
                    </span>
                </base-button>
            </div>
            
            <!-- Input fields appears here after button click -->
            <div v-if="localIsViewClassBreaks">
                <div v-for="(item, index) in classPricingList" :key="index" class="row">
                    <div class="col-lg-3">
                        <base-input
                            type="number"
                            label="Per hundred lbs"
                            step="any"
                            class="mb-0"
                            placeholder="0"
                            :disabled="true"
                            v-model="item.perHundredLbs"
                        >
                        </base-input>
                    </div>

                    <div class="form-group has-label col-lg-4">
                        <label>Price</label>
                        <div>
                            <span
                                class="data-type-input currency-symbol-custom"
                                :data-symbol="currencySymbol"
                                :class="{ 'currency-symbol-dollarSign': currencySymbol === '$' }"
                            >
                                <input
                                    type="number"
                                    step="any"
                                    class="mb-0 form-control"
                                    placeholder="0"
                                    v-model="item.price"
                                />
                            </span>
                    
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <base-input
                            type="number"
                            label="Discount"
                            step="any"
                            class="mb-0"
                            placeholder="%"
                            v-model="item.discount"
                        >
                        </base-input>
                    </div>

                    <div class="col-lg-1 mt-3 mx-1 mx-lg-0 d-flex align-items-center">
                        <i
                            v-if="classPricingList.length > 1"
                            class="fa fa-trash"
                            @click="removeClassPrice(index)"
                        >
                        </i>
                    </div>
                </div>
                <div class="row mb-2">
                <div
                    class="mt-2 col-md-6"
                    >
                    <base-button @click="handleAddPriceClass">
                        <img src="../../assets/img/plus-active.svg" />
                        Add
                    </base-button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { classDataBaseLane } from "@/helpers/utility";
import ChoicesSingle from "@/components/SingleSelect";

export default {
    bodyClass: "landing",
    components: {
        ChoicesSingle
    },
    props: {
        selectedBaseUnit: {
            type: String
        },
        selectedClassUnits: {
            type: String
        },
        isViewClassBreaks: {
            type: Boolean
        },
        currencySymbol: {
            type: String
        },
        classRateInvalid: {
            type: Boolean
        },
    },
    data() {
        return {
            localIsViewClassBreaks: this.isViewClassBreaks, // Create local data based on prop
            localSelectedClassUnits: this.selectedClassUnits,
            classPricingList: [
                { perHundredLbs: null, price: null, discount: null } // Initialize with one set
            ]
        };
    },
    watch: {
        // Watch the prop and update local data if the prop changes
        isViewClassBreaks(newVal) {
            this.localIsViewClassBreaks = newVal;
        },
        selectedClassUnits(newVal) {
            this.localSelectedClassUnits = newVal;
        },
    },
    methods: {
        /**
         * Show class breaks by setting a local flag and emitting an event to the parent component
         */
        showClassBreaks() {
            this.localIsViewClassBreaks = true; 
            this.$emit('update:isViewClassBreaks', true); 
        },
        /**
         * Emits the selected class units to the parent and hides class breaks view
         */
        handleSelectClassUnits() {
            this.$emit('select-class-units', this.localSelectedClassUnits)
            this.$emit('update:isViewClassBreaks', false); 
        },

        // Method to add a new set of inputs to the class pricing list
        handleAddPriceClass() {
            this.classPricingList.push({ perHundredLbs: null, price: null, discount: null });
        },

        // Method to remove a pricing set from the class pricing list by index
        removeClassPrice(i) {
			this.classPricingList.splice(i, 1);
		}  
    },
    computed: {
        classUnitsListResult() {
            return classDataBaseLane.map(option => ({
                id: option.key,
                text: option.name
            }));
        },
    }
}
</script>