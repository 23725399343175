<template>
  <div class="tariff-app-container-height">
    <app-header :activeMenuName="activeMenuName" />
    <loader :isLoader="showLoader"></loader>
    <div class="col-md-12">
      <div class="d-flex mt-md-4 mb-md-5 mt-lg-0 mb-lg-0">
        <base-button
          type="secondary"
          class="btn-theme-FCBB5C sync-btn"
          v-on:click="syncWithPallet"
          :disabled="disabledSyncPalletBtn"
        >
          <img
            :class="{ 'sync-animating': isSyncing }"
            src="../../assets/img/refresh.png"
          />
          Sync to Pallet
        </base-button>
                
        <public-private-toggle 
          v-model="tariffType" 
        />
      </div>
      <div>
        <div class="text-center custom-tab build-tarif-nav">
          <ul
            v-if="isParentView"
            role="tablist"
            class="nav nav-pills-info nav-pills btn-group"
          >
            <li
              v-for="(tab, index) in sectionListItems"
              class="nav-item active"
              data-toggle="tab"
              role="tablist"
              aria-expanded="true"
              :key="index"
            >
              <a
                data-toggle="tab"
                role="tablist"
                @click.prevent="activateTab(tab)"
                :aria-expanded="tab.active"
                class="nav-link tariff-tab"
                :class="{ active: tab.active }"
              >
                {{ tab.item.name }}
              </a>
            </li>
          </ul>
        </div>

        <div class="row tariff-section-fix-height">
          <div class="col-md-3 tariff-section-fix-height-side-panel">
            <div v-if="isParentView">
              <div
                class="mt-3 mb-3 section-title-box-v1 align-items-center d-flex"
              >
                {{ sectionTitle }}
              </div>
              <div class="input-v1 mt-3 mb-3">
                <img
                  class="section-search-icon"
                  src="../../assets/img/search.png"
                />
                <vue-input
                  :placeholder="searchPlacehoder"
                  type="text"
                  class="search-box"
                  v-model="searchSection"
                  @input="event => searchAllSection(event)"
                ></vue-input>
              </div>

              <div v-if="activeTabName == sectionList.lane">
                <div v-for="(node, i) in leftSectionList" :key="i">
                  <div v-if="node.children.length == 1">
                    <div
                      class="tariff-checkmark-list drag-item-box"
                      v-if="!node.children[0].drop"
                    >
                      <drag
                        class="drag drag-icon"
                        :transfer-data="node.children[0]"
                      >
                        <img src="../../assets/img/handle.png" />
                      </drag>

                      <div class="between-icon">
                        <between-svg-icon
                          :isBetween="node.children[0].isBetween"
                        />
                      </div>

                      <div class="mb-2 left-list-checkbox">
                        {{ node.children[0].laneName }}
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="checkDroppableItem(node.children)">
                      <div class="expanded-lane-item mb-2 drag-item-box">
                        <drag
                          class="drag drag-icon"
                          :transfer-data="{ allSubLane: node.children }"
                        >
                          <img src="../../assets/img/handle.png" />
                        </drag>

                        <div class="between-icon">
                          <between-svg-icon :isBetween="false" />
                        </div>

                        <div
                          class="mb-2 left-list-checkbox"
                          @click="toggleLaneList(node)"
                        >
                          {{ node.label }}
                        </div>

                        <div class="list-toggle-icon">
                          <span @click="toggleLaneList(node)" class="expanded">
                            <span v-if="node.children" class="toggle-icon">
                              <img
                                v-if="node.expanded"
                                src="../../assets/img/caret-circle-down.svg"
                              />
                              <img
                                v-if="!node.expanded"
                                src="../../assets/img/caret-circle-up.svg"
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div v-if="node.children && node.expanded" class="mt-2">
                        <div
                          v-for="(item, index) in node.children"
                          :key="index"
                        >
                          <div
                            class="tariff-checkmark-lane-list drag-item-box"
                            v-if="!item.drop"
                          >
                            <drag
                              class="drag drag-icon"
                              :transfer-data="{ item }"
                            >
                              <img src="../../assets/img/handle.png" />
                            </drag>

                            <div class="between-icon">
                              <between-svg-icon :isBetween="item.isBetween" />
                            </div>

                            <div class="mb-2 left-list-checkbox">
                              {{ item.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-for="(item, index) in leftSectionList" :key="index">
                  <div v-if="item.children">
                    <div v-if="!item.drop">
                      <div class="expanded-lane-item mb-2 drag-item-box">
                        <drag
                          class="drag drag-icon"
                          :transfer-data="{ childrenItems: item }"
                        >
                          <img src="../../assets/img/handle.png" />
                        </drag>

                        <div
                          class="mb-2 left-list-checkbox"
                          @click="toggleLaneList(item)"
                        >
                          {{ item.name }}
                        </div>
                        <div class="list-toggle-icon">
                          <span @click="toggleLaneList(item)" class="expanded">
                            <span class="toggle-icon">
                              <img
                                v-if="item.expanded"
                                src="../../assets/img/caret-circle-down.svg"
                              />
                              <img
                                v-if="!item.expanded"
                                src="../../assets/img/caret-circle-up.svg"
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div v-if="item.children && item.expanded" class="mt-2">
                        <div
                          v-for="(childItem, childIndex) in item.children"
                          :key="childIndex"
                        >
                          <div class="tariff-checkmark-lane-list">
                            <div class="mb-2 left-list-checkbox">
                              {{ childItem.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="tariff-checkmark-list drag-item-box"
                      v-if="!item.drop"
                    >
                      <drag class="drag drag-icon" :transfer-data="{ item }">
                        <img src="../../assets/img/handle.png" />
                      </drag>

                      <div class="mb-2 left-list-checkbox">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-9 tariff-right-view-padding tariff-section-fix-height-side-panel"
            :class="{ 'mt-5': !isParentView }"
          >
            <div
              v-if="selectedTariffDetails && isParentView"
              class="mb-2 mr-1 text-right"
            >
              <a class="view-versions-link" @click="showModal">
                View Historical Versions
              </a>
            </div>
            <div class="tariff-section-view mb-4">
              <vue-input
                class="mb-0"
                alternative
                placeholder="Tariff Name"
                type="text"
                addonLeftIcon="fa fa-file-o"
                v-model="name"
                @input="event => changeTariffName(event)"
              ></vue-input>
              <span v-if="nameInvalid" class="validate-error"
                >The Tariff Name field is required</span
              >
              <vue-input
                class="mb-0 mt-3"
                alternative
                placeholder="Tariff Description  (optional)"
                type="text"
                addonLeftIcon="fa fa-file-o"
                v-model="description"
              ></vue-input>
            </div>
            <div class="tariff-section-view">
              <draggable
                tag="ul"
                :list="draggableTableList"
                class="list-group"
                handle=".handle"
              >
                <li
                  class="mb-4 li-list-type"
                  v-for="(element, index) in draggableTableList"
                  :key="element.name"
                >
                  <div class="row mb-2">
                    <div class="col-8 col-md-10 mr-1 mr-md-4">
                      <div class="section-title-box align-items-center d-flex">
                        <span class="draggable-name">{{ element.name }}</span>
                      </div>
                    </div>
                    <div class="col-3 col-md-1 section-drop-icon">
                      <base-button
                        v-if="isParentView"
                        type="primary"
                        class="btn-theme-EFEBEB delete-section"
                        @click="deleteSectionFromTariff(element.name, index)"
                      >
                        <i class="fa fa-trash"></i>
                      </base-button>

                      <span
                        v-if="draggableTableList.length > 1"
                        class="handle handle-box"
                      >
                        <img src="../../assets/img/handle.png" />
                      </span>
                    </div>
                  </div>
                  <drop class="drop" @drop="handleDrop">
                    <div class="">
                      <v-app id="inspire">
                        <!-- Lane table -->
                        <div v-if="element.name == sectionList.lane">
                          <lane-table
                            :tableData="dropListItem(lanesList)"
                            :isTariff="true"
                            :isParentView="isParentView"
                            @remove-item="removeItem"
                          />
                        </div>
                        <!-- Accessorial table -->
                        <div v-if="element.name == sectionList.accessorial">
                          <accessorial-fsc-table
                            :tableData="dropListItem(accessorialList)"
                            :isTariff="true"
                            :isParentView="isParentView"
                            :sectionName="sectionList.accessorial"
                            @remove-item="removeItem"
                          />
                        </div>
                        <!-- Fuel Surcharge table -->
                        <div v-if="element.name == sectionList.fuelSurcharge">
                          <accessorial-fsc-table
                            :tableData="dropListItem(fuelSurchargeList)"
                            :isTariff="true"
                            :isParentView="isParentView"
                            :sectionName="sectionList.fuelSurcharge"
                            @remove-item="removeItem"
                          />
                        </div>
                        <!-- Condition table -->
                        <div v-if="element.name == sectionList.conditions">
                          <condition-table
                            :tableData="dropListItem(conditionList)"
                            :isTariff="true"
                            :isParentView="isParentView"
                            :sectionName="sectionList.conditions"
                            @remove-item="removeItem"
                          />
                        </div>

                        <div
                          v-if="
                            element.name == sectionList.customerOrCustomerGroup
                          "
                        >
                          <expanded-customer-group-table
                            :tableData="dropListItem(customerGroupsList)"
                            :isTariff="true"
                            :isParentView="isParentView"
                            :isPagination="false"
                            :isAction="true"
                            :sectionName="sectionList.customerOrCustomerGroup"
                            @remove-item="removeItem"
                          />
                        </div>

                        <div
                          v-if="element.name == sectionList.classBaseRate"
                        >
                          <base-rate-table
                            :tableData="dropListItem(classBaseRateList)"
                            :isTariff="true"
                            :isParentView="isParentView"
                            :sectionName="sectionList.classBaseRate"
                            @remove-item="removeItem"
                          />
                        </div>
                      </v-app>
                    </div>
                  </drop>
                </li>
              </draggable>

              <div class="row" v-if="isParentView">
                <base-button
                  v-if="addSectionBtn"
                  type="primary"
                  class="btn-theme-24876E col-md-4 col-lg-3 mx-3"
                  v-on:click="addSection"
                >
                  <img src="../../assets/img/plus-active.svg" />
                  Add Section
                </base-button>

                <div
                  v-if="addSectionDropdown"
                  class="add-section-dropdown price-method-dropdwon mr-2 col-md-4 col-lg-4"
                >
                  <choices-single
                    id="addSectionField"
                    :options="sectionFieldList"
                    v-model="selectedField"
                    @input="event => selectField(event)"
                  >
                    <option value="0">Select Section</option>
                  </choices-single>
                </div>

                <base-button
                  type="primary"
                  class="btn-theme-FFFFFF w-100 mt-2 mt-md-0 col-md-2 col-lg-1 p-lg-0 mx-3 mx-md-0"
                  v-on:click="handleSaveUpdateDuplicateTariff"
                >
                  Save
                </base-button>

                <base-button
                  v-if="selectedTariffDetails && isShowSaveVersionButton"
                  type="primary"
                  class="btn-theme-FFFFFF w-100 mt-2 mt-md-0 col-md-4 col-lg-3 p-lg-0 mx-3"
                  @click="showVersionNameModal"
                >
                  Save this Version
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <view-historical-versions
      ref="viewHistoricalVersions"
      @view-tariff-version="setTariffVersionDetail"
    />

    <modal :show.sync="isShowVersionNameModal">
      <div>
        <base-input
          type="text"
          label="Tariff Version Name"
          name="Tariff Version Name"
          class="tariff-version-name-input"
          v-model="tariffVersionName"
          @input="event => changeTariffVersionName(event)"
        >
        </base-input>
        <span v-if="tariffVersionNameInvalid" class="validate-error"
          >The Tariff Version Name field is required</span
        >
      </div>
      <div class="text-right">
        <div>
          <button
            type="button"
            class="btn mt-3 btn-light"
            @click="isShowVersionNameModal = false"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn mt-3 btn-default"
            @click="createTariffVersion"
          >
            Save
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import moment from "moment";
import LaneTable from "@/views/common/LaneTable.vue";
import AccessorialFscTable from "@/views/common/AccessorialFscTable.vue";
import ConditionTable from "@/views/common/ConditionTable.vue";
import ExpandedCustomerGroupTable from "@/views/customers/ExpandedCustomerGroupTable.vue";
import BaseRateTable from "@/views/common/BaseRateTable.vue";
import ViewHistoricalVersions from "@/views/tariff/ViewHistoricalVersions.vue";
import BetweenSvgIcon from "@/views/common/BetweenSvgIcon.vue";
import Loader from "@/views/common/Loader.vue";
import { API, masterAPI } from "@/api/API";
import {
  accountModuleApi,
  dataType,
  filterCustomerObjectsById,
  getAllCustomerOrCustomerGroupApi,
  getMethodKeyByItemObject,
  sectionList,
  getUserApiKey
} from "@/helpers/utility";
import ChoicesSingle from "@/components/SingleSelect";
import { Drag, Drop } from "vue-drag-drop";
import Modal from "@/components/Modal.vue";
import AppHeader from "@/layout/AppHeader.vue";
import PublicPrivateToggle from "@/views/common/PublicPrivateToggle.vue"

export default {
  bodyClass: "landing",
  components: {
    LaneTable,
    AccessorialFscTable,
    Loader,
    BetweenSvgIcon,
    draggable,
    ChoicesSingle,
    Drag,
    Drop,
    ConditionTable,
    ExpandedCustomerGroupTable,
    BaseRateTable,
    ViewHistoricalVersions,
    Modal,
    AppHeader,
    PublicPrivateToggle
  },
  data: function() {
    return {
      // Component's data properties
      activeMenuName: "create-tariff",
      name: "",
      description: "",
      nameInvalid: false,
      dataType: dataType,
      activeTabName: "",
      sectionTitle: "Lane",
      searchPlacehoder: "Search Lane",
      searchSection: "",
      addSectionBtn: true,
      addSectionDropdown: false,
      selectedField: null,
      sectionFieldList: [],
      sectionListItems: [],
      sectionList: sectionList,
      lanesList: [],
      lanesOldList: [],
      accessorialList: [],
      accessorialOldList: [],
      fuelSurchargeList: [],
      fuelSurchargeOldList: [],
      draggableTableList: [],
      dragging: false,
      leftSectionList: [],
      leftOldSectionList: [],
      dbTariffName: "",
      showLoader: false,
      conditionList: [],
      conditionOldList: [],
      customerGroupsList: [],
      customerGroupsOldList: [],
      selectedTariffDetails: null,
      isShowVersionNameModal: false,
      tariffVersionName: "",
      tariffVersionNameInvalid: false,
      parentTariffName: "",
      parentTariffDescription: "",
      isParentView: true,
      isShowSaveVersionButton: false,
      classBaseRateList: [],
      classBaseRateOldList: [],
      tariffType: 'private',
      disabledSyncPalletBtn: true,
      updatedTariffId: null,
      isSyncing: false,
      alreadySavedTariff: false
    };
  },
  props: {
    // Component props
    screenName: {
      type: String
    },
    isCreateTariff: {
      type: Boolean
    }
  },
  computed: {
    /**
     * Represents whether an element is currently being dragged or not.
     * @returns {string} - If dragging is true, returns "under drag", otherwise, returns an empty string.
     */
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    /**
     * Retrieves the ID of the tariff from the route parameters.
     * @returns {number} - The tariff ID from the route parameters.
     */
    routeTariffId() {
      return +this.$route.params.id;
    },
    /**
     * Retrieves the ID of the tariff from the data property.
     * @returns {number|null} - The tariff ID from the API response or null if not available.
     */
    tariffId() {
      return this.updatedTariffId || this.routeTariffId;
    }
  },
  methods: {
    /**
     * Navigates to the previous page in the browser history.
     */
    backView() {
      this.$router.back();
    },
    /**
     * Activates the specified tab and updates the state of the tab.
     *
     * @param {Object} tab - The tab object to activate.
     */
    activateTab(tab) {
      this.deactivateTabs();
      tab.active = true;
      this.handleTabChangingState(tab.item.name);
    },
    /**
     * Deactivates all tabs by setting their 'active' property to false.
     */
    deactivateTabs() {
      this.sectionListItems.forEach(tab => {
        tab.active = false;
      });
    },
    changeTariffName(value) {
      if (value != "") {
        this.nameInvalid = false;
      }
    },
    /**
     * Retrieves all sections and initializes the component's data.
     * Makes an API request to fetch the section data.
     */
    async getAllSection() {
      this.sectionListItems = [];
      this.draggableTableList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.availableSections,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let listData = response.data;

        for (let i = 0; i < listData.length; i++) {
          let sectionObj = {
            item: listData[i],
            active: i == 0 ? true : false
          };
          this.sectionListItems.push(sectionObj);

          let sectionField = {
            id: i,
            text: listData[i].name
          };
          this.sectionFieldList.push(sectionField);
        }

        if (listData.length != 0) {
          this.draggableTableList.push({
            name: listData[0].name
          });

          this.activeTabName = listData[0].name;
        }

        if (this.screenName == "create") {
          this.getAllLanes(false, []);
          this.getAllAccessorial(false, []);
          this.getAllFuelSurcharge(false, []);
          this.getAllCondition(false, []);
          this.getAllCustomerOrCustomerGroup(false, []);
          this.getAllClassBaseRate(false, []);
        } else {
          this.getTariff();
        }
      }
    },
    /**
     * Retrieves the tariff data based on the tariff ID.
     * Makes an API request to fetch the tariff data.
     */
    async getTariff() {
      if (this.tariffId) {
        this.draggableTableList = [];

        let response = await masterAPI(
          API.API_ENDPOINT.tariff,
          API.API_METHOD.get,
          this.tariffId,
          undefined
        );

        if (response.status == 200) {
          let tariffData = response.data;
          this.selectedTariffDetails = tariffData;
          this.setTariffResponseDetail(tariffData);
        }
      }
    },
    /**
     * Sets the tariff response details and updates related properties.
     * @param {Object} tariffData - The tariff data containing details to be set.
     */
    setTariffResponseDetail(tariffData) {
      this.name = tariffData.name;
      this.description = tariffData.description;
      this.dbTariffName = tariffData.name;
      let sectionDetail = tariffData.sectionDetail;
      let lane = sectionDetail.lane;
      let accessorial = sectionDetail.accessorial;
      let fuelSurcharge = sectionDetail.fuelSurcharge;
      let condition = sectionDetail.condition;
      let customerOrCustomerGroup = sectionDetail.customerOrCustomerGroup
        ? sectionDetail.customerOrCustomerGroup
        : [];
      let classBaseRate = sectionDetail.classBaseRate;

      this.getAllLanes(true, lane);
      this.getAllAccessorial(true, accessorial);
      this.getAllFuelSurcharge(true, fuelSurcharge);
      this.getAllCondition(true, condition);
      this.getAllCustomerOrCustomerGroup(true, customerOrCustomerGroup);
      this.getAllClassBaseRate(true, classBaseRate);

      this.displayDraggableTable(lane, sectionList.lane);
      this.displayDraggableTable(accessorial, sectionList.accessorial);
      this.displayDraggableTable(fuelSurcharge, sectionList.fuelSurcharge);
      this.displayDraggableTable(condition, sectionList.conditions);
      this.displayDraggableTable(
        customerOrCustomerGroup,
        sectionList.customerOrCustomerGroup
      );
      this.displayDraggableTable(classBaseRate, sectionList.classBaseRate);

      this.parentTariffName = tariffData.name;
      this.parentTariffDescription = tariffData.description;
      
      if (tariffData.tariffType) {
        this.tariffType = tariffData.tariffType;
      }      
    },
    /**
     * Displays a draggable table based on the provided list and name.
     * Updates the draggableTableList and activeTabName.
     *
     * @param {Array} list - The list of items.
     * @param {string} name - The name of the draggable table.
     */
    displayDraggableTable(list, name) {
      if (list.length > 0) {
        this.draggableTableList.push({
          name: name
        });
        this.activeTabName = this.draggableTableList[0].name;
      }
    },
    /**
     * Retrieves all lanes and initializes the component's data.
     * @param {boolean} isEdit - Indicates if it's in edit mode.
     * @param {Array} lanes - The list of lanes.
     */
    async getAllLanes(isEdit, lanes) {
      this.lanesList = [];
      this.lanesOldList = [];
      this.leftSectionList = [];
      this.leftOldSectionList = [];

      // Retrieve the user's API key, Ensure it is fetched and available from the Vuex store.
      const apiKey = await getUserApiKey(this.$store);

      let response = await masterAPI(
        API.API_ENDPOINT.lanes + "/" + apiKey,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let list = response.data;
        let lanePriceMethodList = await accountModuleApi(
          API.API_ENDPOINT.lanePricingMethods
        );
        for (let i = 0; i < list.length; i++) {
          let listItem = list[i];
          let type = listItem.pricingMethod.type;
          let pricingMethodItem = getMethodKeyByItemObject(
            lanePriceMethodList,
            type
          );
          let pricingMethodName = pricingMethodItem.name;

          let itemObj = {
            name: listItem.name,
            checked: false,
            drop: false,
            origin: listItem.origin,
            destination: listItem.destination,
            subCity: listItem.subCity,
            pricingMethod: type,
            value:
              type == dataType.fixed.toLowerCase()
                ? listItem.pricingMethod.value
                : listItem.pricingMethod.value.sort((a, b) => a.unit - b.unit),
            createdAt: moment(listItem.createdAt).format("MMM Do, YYYY"),
            updatedAt: moment(listItem.updatedAt).format("MMM Do, YYYY"),
            id: listItem.id,
            isBetween: listItem.isBetween,
            description: listItem.description,
            laneName: listItem.origin + " to " + listItem.destination,
            pricingMethodItem: pricingMethodItem,
            pricingMethodTypeName: pricingMethodName,
            accountId: listItem.accountId
          };

          if (isEdit) {
            lanes.map(item => {
              if (item.id == listItem.id) {
                itemObj.drop = true;
              }
            });
          }
          this.lanesList.push(itemObj);
          this.lanesOldList.push(itemObj);
          this.setDropListToLocal("lane", this.lanesList);
        }
        this.leftSectionList = this.groupItemsByOriginAndDestination(
          this.lanesList
        );
        this.leftOldSectionList = this.lanesList;
      }
    },
    /**
     * Retrieves all accessorial items and initializes the component's data.
     * @param {boolean} isEdit - Indicates if it's in edit mode.
     * @param {Array} accessorials - The list of accessorials.
     */
    async getAllAccessorial(isEdit, accessorials) {
      this.accessorialList = [];
      this.accessorialOldList = [];

      // Retrieve the user's API key, Ensure it is fetched and available from the Vuex store.
      const apiKey = await getUserApiKey(this.$store);

      let response = await masterAPI(
        API.API_ENDPOINT.accessorial + "/" + sectionList.accessorial + "/" + apiKey,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let list = response.data;
        for (let i = 0; i < list.length; i++) {
          let listItem = list[i];

          let itemObj = {
            name: listItem.name,
            checked: false,
            drop: false,
            description: listItem.description,
            dataTypeFields: listItem.dataTypeFields,
            createdAt: moment(listItem.createdAt).format("MMM Do, YYYY"),
            updatedAt: moment(listItem.updatedAt).format("MMM Do, YYYY"),
            id: listItem.id,
            moduleType: listItem.moduleType,
            accessorialType: listItem.accessorialType,
            accountId: listItem.accountId,
            maxCharge: listItem.maxCharge,
            minCharge: listItem.minCharge
          };

          if (isEdit) {
            accessorials.map(item => {
              if (item.id == listItem.id) {
                itemObj.drop = true;
              }
            });
          }

          this.accessorialList.push(itemObj);
          this.accessorialOldList.push(itemObj);
          this.setDropListToLocal("accessorial", this.accessorialList);
        }
      }
    },
    /**
     * Retrieves all fuel surcharge items and initializes the component's data.
     * @param {boolean} isEdit - Indicates if it's in edit mode.
     * @param {Array} fuelSurcharges - The list of fuel surcharges.
     */
    async getAllFuelSurcharge(isEdit, fuelSurcharges) {
      this.fuelSurchargeList = [];
      this.fuelSurchargeOldList = [];

      // Retrieve the user's API key, Ensure it is fetched and available from the Vuex store.
      const apiKey = await getUserApiKey(this.$store);

      let response = await masterAPI(
        API.API_ENDPOINT.fuelsurcharge + "/" + sectionList.fuelSurcharge + "/" + apiKey,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let list = response.data;
        for (let i = 0; i < list.length; i++) {
          let listItem = list[i];
          let itemObj = {
            name: listItem.name,
            checked: false,
            drop: false,
            description: listItem.description,
            dataTypeFields: listItem.dataTypeFields,
            createdAt: moment(listItem.createdAt).format("MMM Do, YYYY"),
            updatedAt: moment(listItem.updatedAt).format("MMM Do, YYYY"),
            id: listItem.id,
            moduleType: listItem.moduleType,
            accessorialType: listItem.accessorialType,
            accountId: listItem.accountId,
            maxCharge: listItem.maxCharge,
            minCharge: listItem.minCharge
          };

          if (isEdit) {
            fuelSurcharges.map(item => {
              if (item.id == listItem.id) {
                itemObj.drop = true;
              }
            });
          }

          this.fuelSurchargeList.push(itemObj);
          this.fuelSurchargeOldList.push(itemObj);
          this.setDropListToLocal("fuelSurcharge", this.fuelSurchargeList);
        }
      }
    },
    /**
     * Retrieves all condition items and initializes the component's data.
     * @param {boolean} isEdit - Indicates if it's in edit mode.
     * @param {Array} conditions - The list of conditions.
     */
    async getAllCondition(isEdit, conditions) {
      this.conditionList = [];
      this.conditionOldList = [];

      // Retrieve the user's API key, Ensure it is fetched and available from the Vuex store.
      const apiKey = await getUserApiKey(this.$store);

      let response = await masterAPI(
        API.API_ENDPOINT.conditions + "/" + apiKey,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let list = response.data;
        for (let i = 0; i < list.length; i++) {
          let listItem = list[i];
          listItem.checked = false;
          listItem.drop = false;

          if (isEdit) {
            conditions.map(item => {
              if (item.id == listItem.id) {
                listItem.drop = true;
              }
            });
          }

          this.conditionList.push(listItem);
          this.conditionOldList.push(listItem);
          this.setDropListToLocal("condition", this.conditionList);
        }
      }
    },

    /**
     * Syncs the current tariff with Pallet by first updating the tariff, 
     */
    async syncWithPallet() {
      console.log("HERE");
      this.disabledSyncPalletBtn = true;
      this.isSyncing = true;

      try {
        let tariffResponse;
        // Check the screen name to determine which function to call
        if (this.screenName === "create") {
          // Call saveTariff for "create" screen
          let sectionDetail = this.getSectionDetails();
          tariffResponse = await this.saveTariff(false, sectionDetail, true);
        } else if (this.screenName === "update") {
          // Call updateTariff for "update" screen
          tariffResponse = await this.updateTariff(true);
        }
        
        // Proceed only if the response was successful
        if (tariffResponse && tariffResponse.status === 200) {
          let bodyPayload = JSON.stringify({
              tariffId: this.tariffId
          });

          // Call syncToPallet API
          let response = await masterAPI(
              API.API_ENDPOINT.syncWithPallet,
              API.API_METHOD.post,
              undefined,
              bodyPayload
          );
          
          // Reset button state
          this.disabledSyncPalletBtn = false;
          this.isSyncing = false;

          // Check sync response status
          if (response.status === 200) {
              this.$toast.success("Sync to Pallet successful");
          } else {
              let error = response.data.message;
              this.$toast.error(error.replaceAll('"', ""));
          }
        } else {
          // Handle failure if necessary
          this.disabledSyncPalletBtn = false;
          this.isSyncing = false;
        }
      } catch (error) {
        // Handle any errors that occur
        this.disabledSyncPalletBtn = false;
        this.isSyncing = false;
      }
    },
    /**
     * Retrieves and processes customer and customer group data asynchronously.
     *
     * @param {boolean} isEdit - Indicates whether the operation is in edit mode.
     * @param {Array} customerGroupItems - List of customer group items for editing.
     */
    async getAllCustomerOrCustomerGroup(isEdit, customerGroupItems) {
      this.customerGroupsList = [];
      this.customerGroupsOldList = [];

      // Retrieve the user's API key, Ensure it is fetched and available from the Vuex store.
      const apiKey = await getUserApiKey(this.$store);

      const customerList = await getAllCustomerOrCustomerGroupApi(false, apiKey);
      const customerGroupList = await getAllCustomerOrCustomerGroupApi(true, apiKey);

      const processGroup = async groups => {
        const customers = filterCustomerObjectsById(
          customerList,
          groups.customers
        );
        groups.children = customers;
        groups.checked = false;
        groups.drop = false;
        groups.label = groups.name;
        groups.expanded = false;
        groups.customerGroupId = groups.id;

        if (isEdit) {
          customerGroupItems.forEach(item => {
            if (item.customerGroupId === groups.id) {
              groups.drop = true;
            }
          });
        }

        this.customerGroupsList.push(groups);
        this.customerGroupsOldList.push(groups);
      };

      const processCustomer = async customer => {
        customer.checked = false;
        customer.drop = false;
        customer.customerId = customer.id;

        if (isEdit) {
          customerGroupItems.forEach(item => {
            if (item.customerId === customer.id) {
              customer.drop = true;
            }
          });
        }

        this.customerGroupsList.push(customer);
        this.customerGroupsOldList.push(customer);
      };

      await Promise.all(customerList.map(processCustomer));
      await Promise.all(customerGroupList.map(processGroup));
      this.customerGroupsList.reverse();
      this.setDropListToLocal(
        "customerOrCustomerGroup",
        this.customerGroupsList
      );
    },
    /**
     * Retrieves all class baserate items and initializes the component's data.
     * @param {boolean} isEdit - Indicates if it's in edit mode.
     * @param {Array} baseRateList - The list of class baserate.
    */
    async getAllClassBaseRate(isEdit, baseRateList) {
      this.classBaseRateList = [];
      this.classBaseRateOldList = [];

      let response = await masterAPI(
        API.API_ENDPOINT.classDiscount,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let list = response.data;
        for (let i = 0; i < list.length; i++) {
          let listItem = list[i];
          listItem.checked = false;
          listItem.drop = false;

          if (isEdit) {
            baseRateList.map(item => {
              if (item.id == listItem.id) {
                listItem.drop = true;
              }
            });
          }

          this.classBaseRateList.push(listItem);
          this.classBaseRateOldList.push(listItem);
          this.setDropListToLocal("classBaseRate", this.classBaseRateList);
        }
      }
    },

    /**
     * Handles the selection of a field.
     * Checks if the selected section already exists in the draggableTableList.
     * @param {string} e - The selected field.
     */
    selectField(e) {
      let matchItem = this.draggableTableList
        .map(item => {
          if (item.name == e) {
            return item;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });

      if (matchItem.length > 0) {
        this.$toast.error("This section is already exists");
      } else {
        this.draggableTableList.push({
          name: e
        });

        this.addSectionBtn = true;
        this.addSectionDropdown = false;
        this.deactivateTabs();
        this.sectionListItems.forEach(tab => {
          if (tab.item.name == e) {
            tab.active = true;
          }
        });
        this.handleTabChangingState(e);
      }
    },
    /**
     * Activates the section addition dropdown.
     */
    addSection() {
      this.addSectionBtn = false;
      this.addSectionDropdown = true;
    },
    /**
     * Searches all sections based on the given search value.
     * @param {string} searchValue - The search value.
     */
    searchAllSection(searchValue) {
      let searchItem = this.leftOldSectionList.filter(filterItem => {
        if (this.activeTabName !== "Lane") {
          return filterItem.name
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        }
        if (this.activeTabName === "Lane") {
          return (
            filterItem.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            filterItem.laneName
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          );
        }
        return false;
      });

      if (this.activeTabName === sectionList.lane) {
        this.leftSectionList = this.groupItemsByOriginAndDestination(
          searchItem
        );
      } else if (this.activeTabName === sectionList.customerOrCustomerGroup) {
        this.leftSectionList = searchItem.reverse();
      } else {
        this.leftSectionList = searchItem;
      }
    },
    /**
     * Handles the changing state of the selected tab.
     * @param {string} selectedTab - The selected tab.
     */
    handleTabChangingState(selectedTab) {
      this.searchPlacehoder = "Search " + selectedTab;
      this.sectionTitle = selectedTab;
      this.activeTabName = selectedTab;
      this.leftSectionList = [];
      this.leftOldSectionList = [];
      this.searchSection = "";

      if (selectedTab == sectionList.lane) {
        this.leftSectionList = this.groupItemsByOriginAndDestination(
          this.lanesList
        );
        this.leftOldSectionList = this.lanesList;
      } else if (selectedTab == sectionList.accessorial) {
        this.leftSectionList = this.accessorialList;
        this.leftOldSectionList = this.accessorialList;
      } else if (selectedTab == sectionList.fuelSurcharge) {
        this.leftSectionList = this.fuelSurchargeList;
        this.leftOldSectionList = this.fuelSurchargeList;
      } else if (selectedTab == sectionList.conditions) {
        this.leftSectionList = this.conditionList;
        this.leftOldSectionList = this.conditionOldList;
      } else if (selectedTab == sectionList.customerOrCustomerGroup) {
        this.leftSectionList = this.customerGroupsList;
        this.leftOldSectionList = this.customerGroupsOldList;
      } else if (selectedTab == sectionList.classBaseRate) {
        this.leftSectionList = this.classBaseRateList;
        this.leftOldSectionList = this.classBaseRateOldList;
      }
    },
    /**
     * Checks if the selected section already exists in the draggableTableList.
     * @returns {boolean} - Indicates if the selected section exists.
     */
    isDrop() {
      let matchSection = false;
      document.querySelectorAll(".draggable-name").forEach(item => {
        if (item.innerText.trim() == this.activeTabName) {
          matchSection = true;
        }
      });
      return matchSection;
    },
    /**
     * Filters and returns the dropped items from the list.
     * @param {Array} list - The list of items.
     * @returns {Array} - The filtered dropped items.
     */
    dropListItem(list) {
      let dropItem = list
        .map(filterItem => {
          if (filterItem.drop) {
            return filterItem;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
      return dropItem;
    },
    /**
     * Filters and returns the checked items from the list.
     * @param {Array} list - The list of items.
     * @returns {Array} - The filtered checked items.
     */
    checkedListItem(list) {
      let checkedItem = list
        .map(filterItem => {
          if (filterItem.checked && !filterItem.drop) {
            return filterItem;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
      return checkedItem;
    },
    /**
     * This function checks for checked items in a list.
     *
     * @param {Array} laneList - The input lane list to be checked.
     * @returns {Array} - An array containing only the checked items.
     */
    checkedLaneListItem(laneList) {
      let checkedItem = laneList
        .map(filterItem => {
          return filterItem.children
            .map(item => {
              if (item.checked && !item.drop) {
                return item;
              }
            })
            .filter(function(element) {
              return element !== undefined;
            });
        })
        .filter(function(element) {
          return element !== undefined;
        });
      return checkedItem;
    },
    /**
     * This function checks if all items in the dropList are droppable.
     *
     * @param {Array} dropList - The input list to be checked for droppable items.
     * @returns {boolean} - Returns true if not all items are droppable, otherwise false.
     */
    checkDroppableItem(dropList) {
      let isDrop = true;
      let checkedItem = dropList
        .map(filterItem => {
          if (filterItem.drop) {
            return filterItem;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });

      if (checkedItem.length == dropList.length) {
        isDrop = false;
      }

      return isDrop;
    },
    /**
     * Handles the drop event when an item is dropped into the section.
     * @param {Object} data - The dropped data.
     * @param {Object} event - The drop event.
     */
    handleDrop(data, event) {
      if (event.type == "drop" && this.isDrop()) {
        if (this.activeTabName == this.sectionList.lane) {
          let allSubLane = data.allSubLane;
          if (allSubLane) {
            allSubLane.map(filterItem => {
              let existsLane = this.checkExistDroppedLane(filterItem);
              if (!existsLane) {
                filterItem.drop = true;
              }
            });
          } else {
            let laneList = this.checkedLaneListItem(this.leftSectionList);
            laneList.map(filterItem => {
              if (filterItem.length > 0) {
                filterItem.map(item => {
                  let existsLane = this.checkExistDroppedLane(item);
                  if (!existsLane) {
                    item.drop = true;
                  }
                });
              } else {
                if (data.item) {
                  let existsLane = this.checkExistDroppedLane(data.item);
                  if (!existsLane) {
                    data.item.drop = true;
                  }
                } else {
                  data.drop = true;
                }
              }
            });
          }
        } else {
          let checkData = this.checkedListItem(this.leftSectionList);
          if (checkData.length > 0) {
            checkData.map(item => {
              item.drop = true;
            });
          } else {
            if (this.activeTabName == sectionList.customerOrCustomerGroup) {
              let childrenItems = data.childrenItems;
              if (childrenItems) {
                data.childrenItems.drop = true;
              } else {
                let existsCustomer = this.checkExistDroppedCustomer(data.item);
                if (existsCustomer.length > 0) {
                  let existsCustomerMsg = `The customer you are trying to add is already associated with this tariff as a part of the "${existsCustomer.join(
                    ", "
                  )}" group`;
                  this.$toast.error(existsCustomerMsg);
                } else {
                  data.item.drop = true;
                }
              }
            } else {
              data.item.drop = true;
            }
          }
        }
      }
    },
    /**
     * Checks if a given customer is associated with dropped items in the customerGroupsList.
     *
     * @param {Object} customer - The customer object to check for association.
     * @returns {Array} - Array of group names associated with the dropped customer.
     */
    checkExistDroppedCustomer(customer) {
      let droppedList = this.dropListItem(this.customerGroupsList);

      let associatedGroups = [];

      for (let filterItem of droppedList) {
        if (filterItem.drop && filterItem.customers) {
          let customerIdList = filterItem.customers.filter(
            item => item === customer.id
          );

          if (customerIdList.length > 0) {
            let associatedGroupName = filterItem.name;
            associatedGroups.push(associatedGroupName);
          }
        }
      }

      return associatedGroups;
    },
    checkExistDroppedLane(lane) {
      let droppedLaneList = this.dropListItem(this.lanesList);
      const existsLane = droppedLaneList.some(
        filterItem =>
          lane.origin === filterItem.origin &&
          lane.destination === filterItem.destination &&
          filterItem.drop
      );

      return existsLane;
    },
    /**
     * Removes the drop and checked status of an item.
     * @param {Object} item - The item to remove.
     */
    removeItem(item) {
      item.drop = false;
      item.checked = false;
    },
    /**
     * Handles the save, update, or duplicate action for the tariff based on the screenName.
     */
    handleSaveUpdateDuplicateTariff() {
      if (this.screenName == "create") {
        let sectionDetail = this.getSectionDetails();
        this.showLoader = true;
        if (this.alreadySavedTariff) {
          this.updateTariff();
        } else {
          this.saveTariff(false, sectionDetail);
        }

      } else if (this.screenName == "update") {
        this.updateTariff();
      } else if (this.screenName == "duplicate") {
        this.duplicateTariff();
      }
    },
    /**
     * Retrieves the section details from the selected sections.
     * @returns {Object} - The section details object.
     */
    getSectionDetails() {
      let sectionDetail = {
        lane: this.dropListItem(this.lanesList),
        accessorial: this.dropListItem(this.accessorialList),
        fuelSurcharge: this.dropListItem(this.fuelSurchargeList),
        condition: this.dropListItem(this.conditionList),
        customerOrCustomerGroup: this.dropListItem(this.customerGroupsList),
        classBaseRate: this.dropListItem(this.classBaseRateList)
      };
      return sectionDetail;
    },
    /**
     * Saves the tariff by sending a POST request to the server.
     * @param {boolean} isDuplicate - Indicates if the tariff is being duplicated.
     * @param {Object} sectionDetail - The section detail object.
     * @param {boolean} fromSyncWithPallet - Indicates if the function is called from syncWithPallet.
     */
    async saveTariff(isDuplicate, sectionDetail, fromSyncWithPallet = false) {
      if (this.name == "") {
        this.nameInvalid = true;
        this.showLoader = false;
      } else {
        this.nameInvalid = false;

        let bodyPayload = JSON.stringify({
          name: this.name,
          description: this.description,
          sectionDetail: sectionDetail,
          tariffType: this.tariffType
        });

        let response = await masterAPI(
          API.API_ENDPOINT.tariff,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );

        this.showLoader = false;

        if (response.status == 200) {

          if (this.isCreateTariff) {
            this.alreadySavedTariff = true;
          } 
          
          let msg = isDuplicate
            ? "Tariff duplicate successfully"
            : "Tariff create successfully";

          // Show toast only if it's not called from syncWithPallet
          if (!fromSyncWithPallet) {
            this.disabledSyncPalletBtn = false;
            this.$toast.success(msg, { timeout: 1000 });
          }

          if (isDuplicate) {
            this.goToViewAllTariff();
          } else {
            let tariffData = response.data;
            this.selectedTariffDetails = tariffData;
            this.isShowSaveVersionButton = true;
            this.updatedTariffId = tariffData.id;
          }
        } else {
          let error = response.data.message;
          // Show toast only if it's not called from syncWithPallet
          if (!fromSyncWithPallet) {
            this.$toast.error(error.replaceAll('"', ""));
          }
        }
        // Return the response so it can be handled outside
        return response;
      }
    },
    /**
     * @param {boolean} fromSyncWithPallet - Indicates if the function is called from syncWithPallet.
     * 
     * Updates the tariff by sending a PUT request to the server.
     */
    async updateTariff(fromSyncWithPallet = false) {
      if (this.name == "") {
        this.nameInvalid = true;
      } else {
        this.showLoader = true;
        this.nameInvalid = false;
        let sectionDetail = this.getSectionDetails();

        let bodyPayload = JSON.stringify({
          name: this.name,
          description: this.description,
          sectionDetail: sectionDetail,
          tariffType: this.tariffType
        });

        let response = await masterAPI(
          API.API_ENDPOINT.tariff,
          API.API_METHOD.put,
          this.tariffId,
          bodyPayload
        );

        this.showLoader = false;
        if (response.status == 200) {

          // Show toast only if it's not called from syncWithPallet
          if (!fromSyncWithPallet) {
            this.disabledSyncPalletBtn = false;
            this.$toast.success("Tariff updated successfully", {
                timeout: 1000
            });
          }

          this.isShowSaveVersionButton = true;
          
        } else {
          let error = response.data.message;
           // Show toast only if it's not called from syncWithPallet
           if (!fromSyncWithPallet) {
            this.$toast.error(error.replaceAll('"', ""));
           }
        }
        // Return the response so it can be handled outside
        return response;
      }
    },
    /**
     * Duplicates the tariff by sending a POST request to the server.
     */
    async duplicateTariff() {
      if (this.name == this.dbTariffName) {
        this.$toast.error(
          "Tariff cannot be duplicated until a change has been made to the tariff name"
        );
      } else {
        try {
          this.showLoader = true;

          let lane = await this.duplicateLane();
          let accessorial = await this.duplicateAccessorialFsc(
            this.dropListItem(this.accessorialList)
          );
          let fuelSurcharge = await this.duplicateAccessorialFsc(
            this.dropListItem(this.fuelSurchargeList)
          );
          let condition = await this.duplicateCondition(
            this.dropListItem(this.conditionList)
          );
          let customerOrCustomerGroup = this.dropListItem(
            this.customerGroupsList
          );

          let sectionDetail = {
            lane: lane,
            accessorial: accessorial,
            fuelSurcharge: fuelSurcharge,
            condition: condition,
            customerOrCustomerGroup: customerOrCustomerGroup
          };

          this.saveTariff(true, sectionDetail);
        } catch (error) {
          console.error(error);
        }
      }
    },
    /**
     * Duplicates a lane by sending a POST request to the server.
     * @returns {Promise} - A promise that resolves to the duplicated lane details.
     */
    async duplicateLane() {
      let lanesList = this.dropListItem(this.lanesList);
      let lanePromises = lanesList.map(async lane => {
        let pricingMethod = {
          type: lane.pricingMethod,
          value: lane.value
        };

        let bodyPayload = JSON.stringify({
          origin: lane.origin,
          destination: lane.destination,
          subCity: lane.subCity,
          isBetween: lane.isBetween,
          name: lane.name,
          description: lane.description,
          pricingMethod: pricingMethod
        });

        let response = await masterAPI(
          API.API_ENDPOINT.lane,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );

        let laneDetail = response.data;
        let type = laneDetail.pricingMethod.type;

        laneDetail.checked = false;
        laneDetail.drop = true;
        laneDetail.value =
          type == dataType.fixed.toLowerCase()
            ? laneDetail.pricingMethod.value
            : laneDetail.pricingMethod.value.sort((a, b) => a.unit - b.unit);
        laneDetail.pricingMethod = type;

        return laneDetail;
      });

      return Promise.all(lanePromises);
    },
    /**
     * Duplicates the accessorial or fuel surcharge items by sending a POST request to the server.
     * @param {Array} accFscList - The list of accessorial or fuel surcharge items to duplicate.
     * @returns {Promise} - A promise that resolves to the duplicated accessorial or fuel surcharge details.
     */
    async duplicateAccessorialFsc(accFscList) {
      let accessorialFscPromises = accFscList.map(async item => {
        let bodyPayload = JSON.stringify({
          moduleType: item.moduleType,
          accessorialType: item.accessorialType,
          name: item.name,
          description: item.description,
          dataTypeFields: item.dataTypeFields,
          minCharge: item.minCharge,
          maxCharge: item.maxCharge
        });

        let response = await masterAPI(
          API.API_ENDPOINT.accessorial,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );

        let listDetail = response.data;
        listDetail.checked = false;
        listDetail.drop = true;

        return listDetail;
      });

      return Promise.all(accessorialFscPromises);
    },
    /**
     * Duplicates the conditions items by sending a POST request to the server.
     * @param {Array} conditionData - The list of conditions items to duplicate.
     */
    async duplicateCondition(conditionData) {
      let conditionPromises = conditionData.map(async item => {
        let bodyPayload = JSON.stringify({
          conditionType: item.conditionType,
          name: item.name,
          description: item.description,
          dataTypeFields: item.dataTypeFields,
          conditionTypeFields: item.conditionTypeFields,
          minCharge: item.minCharge,
          maxCharge: item.maxCharge
        });

        let response = await masterAPI(
          API.API_ENDPOINT.condition,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );

        let listDetail = response.data;
        listDetail.checked = false;
        listDetail.drop = true;

        return listDetail;
      });

      return Promise.all(conditionPromises);
    },
    /**
     * Navigates to the view all tariff page.
     */
    goToViewAllTariff() {
      this.$router
        .push({ path: "/view-all-tariff", name: "view-all-tariff" })
        .catch(() => {});
    },
    /**
     * Groups items by their origin and destination.
     *
     * @param {Array} items - The input items to be grouped.
     * @returns {Array} - An array of grouped data objects.
     */
    groupItemsByOriginAndDestination(items) {
      const groupedData = {};

      // Iterate through the data and group by origin and destination
      items.forEach(item => {
        const key = `${item.origin} to ${item.destination}`;
        if (!groupedData[key]) {
          groupedData[key] = {
            label: key,
            children: [],
            expanded: false
          };
        }

        groupedData[key].children.push(item);
      });

      // Convert the groupedData object into an array
      const laneGroupedData = Object.values(groupedData);
      return laneGroupedData;
    },
    /**
     * Toggles the 'expanded' property of a node if it has children.
     *
     * @param {Object} node - The node to be toggled.
     */
    toggleLaneList(node) {
      if (node.children) {
        node.expanded = !node.expanded;
      }
    },
    /**
     * Deletes a section from the tariff based on the section name and index.
     *
     * @param {string} sectionName - The name of the section to be deleted.
     * @param {number} sectionIndex - The index of the section to be deleted.
     */
    deleteSectionFromTariff(sectionName, sectionIndex) {
      this.draggableTableList.splice(sectionIndex, 1);
      let removeDropItemsList = [];
      if (sectionName == sectionList.lane) {
        removeDropItemsList = this.lanesList;
      } else if (sectionName == sectionList.accessorial) {
        removeDropItemsList = this.accessorialList;
      } else if (sectionName == sectionList.fuelSurcharge) {
        removeDropItemsList = this.fuelSurchargeList;
      } else if (sectionName == sectionList.conditions) {
        removeDropItemsList = this.conditionList;
      } else if (sectionName == sectionList.customerOrCustomerGroup) {
        removeDropItemsList = this.customerGroupsList;
      } else if (sectionName == sectionList.classBaseRate) {
        removeDropItemsList = this.classBaseRateList;
      }

      removeDropItemsList.map(item => {
        item.drop = false;
        item.checked = false;
      });
    },
    /**
     * Shows the modal for viewing historical versions of the selected tariff.
     */
    showModal() {
      this.$refs.viewHistoricalVersions.openModal(this.selectedTariffDetails);
    },
    /**
     * Shows the modal for entering a new tariff version name.
     */
    showVersionNameModal() {
      this.isShowVersionNameModal = true;
      this.tariffVersionName = "";
    },
    /**
     * Handles the change event for the tariff version name input field.
     * @param {string} value - The new value of the tariff version name input field.
     */
    changeTariffVersionName(value) {
      if (value != "") {
        this.tariffVersionNameInvalid = false;
      }
    },
    /**
     * Sets a list to local storage.
     * @param {string} fieldKey - The key to identify the local storage field.
     * @param {Array} list - The list to be stored.
     */
    setDropListToLocal(fieldKey, list) {
      let dropList = this.dropListItem(list);
      localStorage[fieldKey] = JSON.stringify(dropList);
    },
    /**
     * Removes a list from local storage.
     * @param {string} fieldKey - The key of the local storage field to be removed.
     */
    removeDropListToLocal(fieldKey) {
      localStorage.removeItem(fieldKey);
    },
    /**
     * Retrieves a list from local storage.
     * @param {string} fieldKey - The key of the local storage field to be retrieved.
     * @returns {string|null} - The stored list or null if not found.
     */
    getDropListToLocal(fieldKey) {
      return localStorage.getItem(fieldKey);
    },
    /**
     * Checks whether a new tariff version should be created based on user inputs.
     * @returns {boolean} - True if a new version should be created, false otherwise.
     */
    shouldCreateTariffVersion() {
      if (this.tariffVersionName === "") {
        this.tariffVersionNameInvalid = true;
        return false;
      }

      this.tariffVersionNameInvalid = false;
      const sectionDetail = this.getSectionDetails();
      const {
        lane,
        accessorial,
        fuelSurcharge,
        condition,
        customerOrCustomerGroup
      } = sectionDetail;

      const localLane = this.getDropListToLocal("lane");
      const localAccessorial = this.getDropListToLocal("accessorial");
      const localFuelSurcharge = this.getDropListToLocal("fuelSurcharge");
      const localCondition = this.getDropListToLocal("condition");
      const localCustomerOrCustomerGroup = this.getDropListToLocal(
        "customerOrCustomerGroup"
      );

      return (
        this.name !== this.parentTariffName ||
        this.description !== this.parentTariffDescription ||
        localLane !== JSON.stringify(lane) ||
        localAccessorial !== JSON.stringify(accessorial) ||
        localFuelSurcharge !== JSON.stringify(fuelSurcharge) ||
        localCondition !== JSON.stringify(condition) ||
        localCustomerOrCustomerGroup !== JSON.stringify(customerOrCustomerGroup)
      );
    },
    /**
     * Creates a new tariff version based on the entered name and selected tariff details.
     */
    async createTariffVersion() {
      let sectionDetail = this.getSectionDetails();
      let shouldCreate = this.shouldCreateTariffVersion();

      if (shouldCreate) {
        this.showLoader = true;

        let bodyPayload = JSON.stringify({
          name: this.tariffVersionName,
          description: this.description,
          sectionDetail: sectionDetail
        });

        let endPoint =
          API.API_ENDPOINT.tariff +
          "/" +
          this.selectedTariffDetails.id +
          "/" +
          API.API_ENDPOINT.tariffVersion;

        let response = await masterAPI(
          endPoint,
          API.API_METHOD.post,
          undefined,
          bodyPayload
        );

        this.showLoader = false;

        if (response.status == 200) {
          this.isShowVersionNameModal = false;
          this.$toast.success("Tariff version create successfully");
          this.isShowSaveVersionButton = false;
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ""));
        }
      } else {
        this.isShowVersionNameModal = false;
        this.isShowSaveVersionButton = false;
        this.$toast.error(
          "Cannot be create tariff version until a change as been made"
        );
      }
    },
    /**
     * Sets tariff version details for viewing and editing.
     * @param {Object} selectedTariffVersion - The selected tariff version object.
     */
    setTariffVersionDetail(selectedTariffVersion) {
      this.draggableTableList = [];
      this.isParentView = false;
      this.setTariffResponseDetail(selectedTariffVersion);
    }
  },
  created() {
    this.getAllSection();
  }
};
</script>
<style scoped>
.toggle-icon {
  cursor: pointer;
}

.expanded {
  cursor: pointer;
}

.expanded-lane-item {
  display: flex;
}

.expanded-lane-item .expanded {
  padding-right: 21px;
}
</style>
